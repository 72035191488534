import React, { useState } from 'react';

const InformationNote = ({ profile, selectedFormula, setSelectedFormula  }) => {

    const handleOptionClick = (option) => {
        setSelectedFormula(option);
    };

    const isFemale = profile?.gender == 'female';

    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold mb-4">Note d'Utilisation de la Plateforme Alkhayri</h1>
            <p>Alkhayri est la plateforme de rencontre pour adultes avec des projets de mariage. Voici quelques informations importantes sur l'utilisation de notre service :</p>

            <h2 className="text-xl font-semibold mt-4 mb-3">Comment fonctionne Alkhayri ?</h2>
            <p>
                - <strong>Confidentialité :</strong> Les échanges initiaux se font par email ou via le chat disponible sur la plateforme pour respecter votre vie privée. L'échange de numéros de téléphone est une décision personnelle.
                <br />
                - <strong>Sélection de Profils :</strong> Vous recevrez des profils de personnes susceptibles de vous correspondre. Si le courant passe, vous pourrez décider de partager vos numéros de téléphone.
                <br />
                - <strong>Respect des Règles :</strong> Il est interdit de transmettre les profils reçus à des amis non-inscrits sur Alkhayri. Toute tentative de transgression entraînera l'exclusion immédiate de la plateforme.
            </p>

            <h2 className="text-xl font-semibold mt-4">
                Tarification
               
            </h2>
            <small className=''>Selectionner une formule</small>
            <div className="mt-2">
                <button
                    className={`p-2 m-2 border ${selectedFormula === 'standard' ? 'bg-red-500 text-white' : ''}`}
                    onClick={() => handleOptionClick('standard')}
                >
                    Formule standard : Activation + Matching (39€)
                </button>
                {isFemale && (
                    <button
                        className={`p-2 m-2 border ${selectedFormula === 'basic' ? 'bg-red-500 text-white' : ''}`}
                        onClick={() => handleOptionClick('basic')}
                    >
                        Formule basic : Activation simple (19€)
                    </button>
                )}
            </div>

            <div className="mt-4">
                <p><strong>Formule standard (Recommandée) :</strong> Votre compte sera activé dès que le paiement sera effectué. Vous pourrez sélectionner jusqu'à 15 profils parmi une liste évolutive, mise à jour avec les nouveaux inscrits sur plusieurs jours. Consultez les détails de chaque profil et faites un match si un profil vous intéresse.</p>
            </div>


            <div className="mt-4">
                <p><strong>Formule basic :</strong> Votre compte sera activé une fois le paiement effectué. Vous pourrez échanger avec les personnes qui matchent avec votre profil mais vous ne pourrez pas initier des matchs. Si vous souhaitez accéder à la formule de sélection de profils, Veillez choisir la formule standard.</p>
            </div>

            <p className='text-red-300 mt-5'>* Non remboursable </p>
        </div>
    );
};

export default InformationNote;