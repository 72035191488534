import { useNavigate } from "react-router-dom";
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { useEffect, useState } from "react";
import { getElement } from "../../configs/http-connect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaArrowRight } from "react-icons/fa";

import { getAge } from "../../utilities/calcul";
import MenuHeader from "../../components/MenuHeader";
import InformationNote from "../../components/InformationCheckout";
import PulseLoader from "react-spinners/PulseLoader";
import { matchLevel } from "../../utilities/matchLevel";
import { translate } from "../../utilities/lng";

function HomeSpace({ profile, toggleRightMenu, onMenuClick }) {
  const navigate = useNavigate();
  const [ageRangeCount, setAgeRangeCount] = useState(0);
  const [nationalityCount, setNationalityCount] = useState(0);
  const [religionCount, setReligionCount] = useState(0);
  const [residenceCountryCount, setResidenceCountryCount] = useState(0);
  const [matchedProfiles, setMatchedProfiles] = useState([]);
  const [totalMatch, setTotalMatch] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour le modal
  const [loading, setLoading] = useState(false);
  const [errorCheckout, setErrorCheckout] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState('standard');



  const getProfileCounts = () => {
    getElement('candidate/profile_counts_by_criteria/').then(res => {
      setAgeRangeCount(res.data.age_range_count);
      setNationalityCount(res.data.nationality_count);
      setReligionCount(res.data.religion_count);
      setResidenceCountryCount(res.data.residence_country_count);
      setTotalMatch(res.data.total_match)
    }).catch(err => {
      console.log('Error ::: ', err);
    });
  };

  const getMatchedProfiles = () => {
    getElement('candidate/interesting_profile/').then(res => {
      setMatchedProfiles(res.data);
    }).catch(err => {
      console.log('Error ::: ', err);
    });
  };

  useEffect(() => {
    getProfileCounts();
    getMatchedProfiles();
  }, [navigate]);

  const dataMatchPays = {
    labels: ['Match', 'Autres'],
    datasets: [
      {
        data: [residenceCountryCount, totalMatch - residenceCountryCount],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  const dataMatchReligions = {
    labels: ['Match', 'Autres'],
    datasets: [
      {
        data: [religionCount, totalMatch - religionCount],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  const dataMatchAge = {
    labels: ['Match', 'Autres'],
    datasets: [
      {
        data: [ageRangeCount, totalMatch - ageRangeCount],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  const dataMatchNationalite = {
    labels: ['Match', 'Autres'],
    datasets: [
      {
        data: [nationalityCount, totalMatch - nationalityCount],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const sliderSettings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const openInNewTab = (url) => {
    setTimeout(() => {
      window.open(url, "_blank");
      if (!window.open(url, "_blank")) {
          window.location.href = url;
      }
  });
  }


  const getCheckoutUrl = () => {
    setLoading(true);
    getElement('candidate/checkout-url/' + selectedFormula).then(res => {
      openInNewTab(res.data.url);
    }).catch(err => {
      setLoading(false);
      setErrorCheckout(true);
    });
  }

  const goToMatch = () => {
    onMenuClick('match')
  }

  const handlePayButtonClick = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  return (
    <div className="bg-gray-100 h-screen pt-0 lg:p-10 lg:pt-0 w-full overflow-auto">
      <div className="w-full md:flex md:justify-center">
        <div className="md:w-[620px] p-3">
          <MenuHeader toggleRightMenu={toggleRightMenu} />
          <div className="flex flex-col  bg-gray-100 py-4">
            <h1 className="text-3xl font-bold text-gray-800 mb-2">Bienvenue sur ALKHAYRI</h1>
            <h2 className="text-2xl text-gray-600">
              Préparez-vous à rencontrer votre âme sœur !
            </h2>
          </div>
          {!profile?.payed && (
            <h4 className="text-xl text-[#4c878d] my-0">
              Ne manquez pas l'opportunité de matcher avec les meilleurs profils. Activez votre compte
            </h4>
          )}
          <div>
            {profile?.payed ?
              <button type="button" className="shadow-sm border w-full bg-gray-50 border-gray text-gray px-4 py-3 rounded-md flex items-center my-4 border-green-500 cursor-pointer" onClick={goToMatch}>
                Voir mes matches <FaArrowRight color="red" className="ml-4" />
              </button> :
              <button type="button" className="shadow-sm border w-full bg-gray-50 border-gray text-red-500 px-4 py-3 rounded-md flex items-center my-2" onClick={handlePayButtonClick}>
                Activez mon compte <FaArrowRight color="red" className="ml-4" />
              </button>
            }
            {!profile?.payed && profile?.unblocked && (
              <div className="text-sm text-gray-600 my-0 py-2">
                🌟 Votre compte est débloqué. Rendez-vous dans ☰menu → chat pour discuter avec vos matchs.
              </div>
            )}
            <span className="my-3 text-2xl text-gray-600 mb-4">Des profils qui pourraient vous intéresser</span>
            <div className="mt-4 mb-4">
              {matchedProfiles.length > 0 ? (
                <Slider {...sliderSettings}>
                  {matchedProfiles.map((match, index) => (
                    <div key={index} className="bg-gray-50 p-4 shadow rounded-lg mb-2">
                      <p className="text-gray-700">
                        <span className="text-lg"> {match.name} {getAge(match.birth_date)} </span> <small className="ml-2">{matchLevel(match.match_percentage)}</small>
                      </p>
                      <p className="text-gray-400">{match.residence_country} <span className="border-red-200"> {match.residence_city} </span></p>

                      <div className="flex flex-wrap gap-1 rounded my-1 max-w-full">
                        {/* Display criteria when value is greater than 30 */}
                        {Object.entries(match.criteria).map(([key, value], index) => (
                          value > 30 && (
                            <div
                              key={index}
                              className="cursor-pointer px-2 border border-red-200 rounded-md bg-transparent text-neutral-600 text-sm"
                            >
                              {translate[key]}
                            </div>
                          )
                        ))}
                      </div>

                      <p className="text-gray-500 text-sm">
                        Bio : {match?.bio?.substring(0, 80) + "..."}
                      </p>
                    </div>
                  ))}
                </Slider>
              ) : (
                <p>Chargement des correspondances...</p>
              )}
            </div>


            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="bg-white p-4 rounded-lg text-center border-2 border-green-500">
                <div className="text-2xl font-bold text-red-500">+1000</div>
                <div className="text-black-100">Matchs</div>
              </div>
              <div className="bg-gray-200 p-4 rounded-lg text-center">
                <div className="h-24 w-24 mx-auto mb-2">
                  <Doughnut data={dataMatchNationalite} options={options} />
                </div>
                <div className="text-gray-600">Match Nationalité</div>
              </div>
              <div className="bg-gray-200 p-4 rounded-lg text-center">
                <div className="h-24 w-24 mx-auto mb-2">
                  <Doughnut data={dataMatchReligions} options={options} />
                </div>
                <div className="text-gray-600">Match Religions</div>
              </div>
              <div className="bg-gray-200 p-4 rounded-lg text-center">
                <div className="h-24 w-24 mx-auto mb-2">
                  <Doughnut data={dataMatchAge} options={options} />
                </div>
                <div className="text-gray-600">Match Age</div>
              </div>
              <div className="bg-gray-200 p-4 rounded-lg text-center">
                <div className="h-24 w-24 mx-auto mb-2">
                  <Doughnut data={dataMatchPays} options={options} />
                </div>
                <div className="text-gray-600">Match Pays</div>
              </div>
            </div>
          </div>

        </div>

      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 overflow-auto py-10">
          <div className="bg-white p-4 rounded-lg shadow-lg w-[620px]">
            <InformationNote profile={profile} selectedFormula={selectedFormula} setSelectedFormula={setSelectedFormula} />

            <div className="flex justify-end">
              {errorCheckout && <p className="text-red-500"> Cliquez sur la formule choisie, puis réessayez. </p>}
              <button className="mr-2 bg-gray-200 px-4 py-2 rounded" onClick={closeModal}>Annuler</button>
              <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={getCheckoutUrl}>{loading ? <PulseLoader color='white' size={10} /> : 'Confirmer'}</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default HomeSpace;
